/*!

=========================================================
* BLK Design System PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

//import slider
import Slider from "react-slick";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  NavItem,
  NavLink
} from "reactstrap";

//google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

import tech1 from "assets/img/1.png";
import tech2 from "assets/img/2.png";
import tech3 from "assets/img/3.png";
import tech4 from "assets/img/4.png";
import tech5 from "assets/img/5.png";
import tech6 from "assets/img/6.png";
import tech7 from "assets/img/7.png";
import tech8 from "assets/img/8.png";
import tech9 from "assets/img/9.png";
import tech10 from "assets/img/10.png";

// core components

const ContactUsMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: 33.7886792, lng: -84.3907915 }}
      defaultOptions={{
        scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
        styles: [
          {
            elementType: "geometry",
            stylers: [
              {
                color: "#1d2c4d"
              }
            ]
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#8ec3b9"
              }
            ]
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1a3646"
              }
            ]
          },
          {
            featureType: "administrative.country",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#4b6878"
              }
            ]
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#64779e"
              }
            ]
          },
          {
            featureType: "administrative.province",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#4b6878"
              }
            ]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#334e87"
              }
            ]
          },
          {
            featureType: "landscape.natural",
            elementType: "geometry",
            stylers: [
              {
                color: "#023e58"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#283d6a"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#6f9ba5"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1d2c4d"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#023e58"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#3C7680"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#304a7d"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#98a5be"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1d2c4d"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#2c6675"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#21CE99"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#21CE99"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#b0d5ce"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#023e58"
              }
            ]
          },
          {
            featureType: "transit",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#98a5be"
              }
            ]
          },
          {
            featureType: "transit",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1d2c4d"
              }
            ]
          },
          {
            featureType: "transit.line",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#283d6a"
              }
            ]
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
              {
                color: "#3a4762"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#0e1626"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#4e6d70"
              }
            ]
          }
        ]
      }}
    >
      <Marker position={{ lat: 33.7886792, lng: -84.3907915 }} />
    </GoogleMap>
  ))
);

var settings = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2300,
  pauseOnHover: true
};

var techs = [
  tech9,
  tech10,
  tech1,
  tech2,
  tech3,
  tech4,
  tech5,
  tech6,
  tech7,
  tech8
];

class Features extends React.Component {
  state = {};

  render() {
    return (
      <>
        <div className="cd-section" id="features">
          {/* ********* FEATURES 2 ********* */}
          <div className="features-2" id="services">
            <Container>
              <Row className="align-items-center">
                <Col className="mr-auto text-left" lg="3" md="8">
                  <h1 className="title text-primary">
                    Specializing in robust data and automation.
                  </h1>
                  <p className="description">
                    We are a full-service data solutions provider that works
                    with clients to maximize the effectiveness of their business
                    through the adoption of digital technology.
                  </p>
                  <br />
                  {/*<Button
                    className="btn-simple"
                    color="info"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    Learn more <i className="tim-icons icon-alert-circle-exc" />
                  </Button>*/}
                </Col>
                <Col lg="8" md="12">
                  <Row>
                    <Col lg="4" md="4">
                      <div className="info text-left bg-default">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-components" />
                        </div>
                        <h4 className="info-title">Platform Development</h4>
                        <p className="description">
                          You dream, we build it. Whether it's an iOS app or an online platform, we can take your idea and turn
                          it into a reality.
                        </p>
                      </div>
                    </Col>
                    <Col lg="4" md="4">
                      <div className="info text-left bg-danger info-raised">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-chart-bar-32" />
                        </div>
                        <h4 className="info-title">
                          Data Analytics & Visualization
                        </h4>
                        <p className="description">
                          Feeling lost with vast amounts of data? We can help
                          you make sense of it all and wrangle the power of your
                          data into insightful views
                        </p>
                      </div>
                    </Col>
                    <Col lg="4" md="4">
                      <div className="info text-left bg-success">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-single-02" />
                        </div>
                        <h4 className="info-title">Social Media Automation</h4>
                        <p className="description">
                          The best ROI for reaching to customers. We can
                          effectively target users based on their interests and
                          directly interact with them
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4" md="4">
                      <div className="info text-left bg-info">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-settings-gear-63" />
                        </div>
                        <h4 className="info-title">Process Automation</h4>
                        <p className="description">
                          Our solutions can fully automate essential business
                          processes or optimize the way people work assisted
                          automation
                        </p>
                      </div>
                    </Col>
                    <Col lg="4" md="4">
                      <div className="info text-left bg-warning info-raised">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-cloud-download-93" />
                        </div>
                        <h4 className="info-title">Data Mining</h4>
                        <p className="description">
                          If it's public, we can aggregate it. Gather insights
                          that you never thought were possible
                        </p>
                      </div>
                    </Col>
                    <Col lg="4" md="4">
                      <div className="info text-left bg-newcolor">
                        <div className="icon icon-white">
                          <i className="tim-icons icon-atom" />
                        </div>
                        <h4 className="info-title">AI & Machine Learning</h4>
                        <p className="description">
                          Adopt the functionalities of AI and machine learning
                          to get your business to new heights
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END FEATURES 2 ********* */}
          {/* ********* FEATURES 5 ********* */}
          <div className="features features-5">
            <Container>
              <Row className="align-items-center">
                <Col lg="6">
                  <div className="featured-image">
                    <img
                      alt="..."
                      height="600"
                      src={require("assets/img/atlanta.jpg")}
                      //width="500"
                    />
                  </div>
                </Col>
                <Col className="mt-md-5" lg="6">
                  <h6 className="category">About Us</h6>
                  <h1 className="title text-primary">Company Values</h1>
                  <Row>
                    <Col lg="6" md="6">
                      <div className="info">
                        <h4 className="info-title">Reliable</h4>
                        <p className="description">
                          Focused on providing emerging technologies with
                          flexible engagement models
                        </p>
                      </div>
                      <div className="info">
                        <h4 className="info-title">Efficient</h4>
                        <p className="description">
                          Outstanding developers paired with in-depth planning
                          always equates to a timely delivery
                        </p>
                      </div>
                    </Col>
                    <Col lg="6" md="6">
                      <div className="info">
                        <h4 className="info-title">Helpful</h4>
                        <p className="description">
                          We partner with your team from idea to scaled
                          deployment in a customer-centric approach.
                        </p>
                      </div>
                      <div className="info">
                        <h4 className="info-title">Quality</h4>
                        <p className="description">
                          Before we release your software, it goes through a
                          thorough testing process to ensure it is working in
                          the intended manner
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END FEATURES 5 ********* */}
          {/* ********* technologies   ********* */}
          <div
            className="features-2"
            style={{ paddingBottom: "0px", width: "100%" }}
            id="technologies"
          >
            <h1 className="title text-primary">Technologies</h1>
            <div className="bg-primary">
              <Slider {...settings}>
                {techs.map(tool => (
                  <div>
                    <img src={tool} alt="tools"></img>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          {/* ********* end technologies ********* */}
          {/* ********* CONTACT US 2 ********* */}
          <div className="cd-section" id="contactus">
            <div className="contactus-2">
              <h1
                className="title text-primary"
                style={{ textAlign: "center" }}
              >
                Contact Us
              </h1>
              <ContactUsMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB9vtKfS6oSBjLgFhBI1evohk1Oqcjt-ik"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div className="map" style={{ height: `100%` }} />}
              />
              <Col lg="6" md="10">
                <Card className="card-contact card-raised bg-bground">
                  <Form
                    id="contact-form"
                    method="post"
                    role="form"
                    onSubmit={e => e.preventDefault()}
                  >
                    <CardHeader className="text-center">
                      <CardTitle tag="h4">Reach Out</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md="6">
                          <div className="info info-horizontal">
                            <div className="icon icon-primary">
                              <i className="tim-icons icon-mobile" />
                            </div>
                            <div className="description">
                              <h5 className="info-title">Give us a ring</h5>
                              <p>
                                Chris Reynolds <br />
                                678 517 5567 <br />
                                Mon-Fri, 8:00-22:00
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="info info-horizontal">
                            <div className="icon icon-primary">
                              <i className="tim-icons icon-square-pin" />
                            </div>
                            <div className="description">
                              <h5 className="info-title">
                                Find us at the office
                              </h5>
                              <p>
                                512 Boulevard Pl NE <br />
                                Atlanta, GA 30308
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <label>Full name</label>
                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state
                                .firstNameContact2Focus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-single-02" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              autoComplete="given-name"
                              placeholder="First Name..."
                              type="text"
                              onFocus={e =>
                                this.setState({ firstNameContact2Focus: true })
                              }
                              onBlur={e =>
                                this.setState({ firstNameContact2Focus: false })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col md="6">
                          <label>Email address</label>
                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.emailContact2Focus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-email-85" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              autoComplete="email"
                              placeholder="Email Here..."
                              type="email"
                              onFocus={e =>
                                this.setState({ emailContact2Focus: true })
                              }
                              onBlur={e =>
                                this.setState({ emailContact2Focus: false })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md="6">
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" />
                              <span className="form-check-sign" />
                              I'm not a robot
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <Button
                            className="btn-round pull-right"
                            color="primary"
                          >
                            Send Message
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Form>
                </Card>
              </Col>
            </div>
          </div>
          {/* ********* END CONTACT US 2 ********* */}

          {/* ********* SIMPLE FOOTER ********* */}
          <div
            className="section section-footers"
            data-background-color="gray"
            style={{ marginTop: "100px", paddingBottom: "0px" }}
          >
            <footer className="footer footer-simple">
              <Container>
                <nav>
                  <ul>
                    <li>
                      <NavLink href="#">About Us</NavLink>
                    </li>
                    <li>
                      <NavLink href="#" className="ml-1">
                        Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink href="#" className="ml-1">
                        Technologies
                      </NavLink>
                    </li>
                    <li>
                      <NavLink href="#" className="ml-1">
                        Blog
                      </NavLink>
                    </li>
                  </ul>
                </nav>
                <div className="copyright">
                  © {new Date().getFullYear()}, Designed by{" "}
                  <a
                    className="copyright-link"
                    href="#"
                    rel="noopener noreferrer"
                  >
                    Vulture DataLabs, LLC
                  </a>
                  .
                </div>
              </Container>
            </footer>
          </div>
          {/* ********* END SIMPLE FOOTER ********* */}
        </div>{" "}
      </>
    );
  }
}

export default Features;
