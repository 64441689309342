import React from "react";

// reactstrap components
// import {
//
// } from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.jsx";
import VultureNavbar from "components/Navbars/VultureNavbar.jsx";
import Features from "views/VultureSections/Features";

// Sections for this page
// (we've divided this page into multiple react components to make it a bit more readable)
import VultureHeader from "components/Headers/VultureHeader.jsx"


class Presentation extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (
      window.location.href.lastIndexOf("#") > 0 &&
      document.getElementById(href) !== null
    ) {
      document.getElementById(href).scrollIntoView();
    }
  }
  render() {
    return (
      <>
        <VultureNavbar />
        <div className="wrapper" ref="wrapper">
          <VultureHeader />
          <Features />

        </div>
      </>
    );
  }
}

export default Presentation;
